import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import injectSheet from 'react-jss';
import { CheckPhonePopup } from 'ui/components/organisms/Customer/CompleteOrder/CheckPhonePopup';
import { CustomerTermsForm } from '../../organisms/Customer/CompleteOrder/CustomerTermsForm';
import { OrderSummaryList } from '../../organisms/Customer/CompleteOrder/OrderSummaryList';
import { thunks } from '../../../../services/thunks';
import { Addresses } from './Addresses';
import { anonymousOrder } from '../../../styles/Customer/CompleteOrder/anonymousOrder';
import { ResendCheckPhonePopup } from 'ui/components/organisms/Customer/CompleteOrder/ResendCheckPhonePopup';
import { palette } from 'ui/styles/theme';
import { Event } from '../../tracking';

const mapStateToProps = state => ({
  addresses: state.getIn(['app', 'addresses']),
  pendingOrder: state.getIn(['app', 'pendingOrder']),
  phone: state.getIn(['app', 'user', 'phone']),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      sendCustomerOrder: thunks.sendCustomerOrder,
      onEnterCustomerOrder: thunks.onEnterCustomerOrder,
      resendLink: thunks.resendLink,
    },
    dispatch,
  );

class _CustomerOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isResetConfirmationOpen: false };
    this.sendCustomerOrder = this.sendCustomerOrder.bind(this);
  }

  componentDidMount() {
    this.props.onEnterCustomerOrder();
  }

  resendLink = () => {
    const { resendLink, pendingOrder } = this.props;
    this.setState({ isResetConfirmationOpen: true });
    resendLink(pendingOrder);
  };

  sendCustomerOrder(setErrors, setSubmitting) {
    Event('User', 'conversion_event_purchase', 'Conversion Event Purchase');

    this.props.sendCustomerOrder(setErrors, setSubmitting);
  }

  render() {
    const { classes, addresses, pendingOrder, phone } = this.props;
    return (
      <Fragment>
        <Addresses addresses={addresses} canSelect />
        <CustomerTermsForm
          id="customerTermsForm"
          sendCustomerOrder={this.sendCustomerOrder}
        />
        <span className={classes.title2}>Order Summary</span>
        <OrderSummaryList />
        <button
          type="submit"
          form="customerTermsForm"
          className={classes.submit}
        >
          Order
        </button>
        {pendingOrder && (
          <>
            <button
              type="button"
              onClick={this.resendLink}
              className={classes.resendLink}
            >
              Resend text <br /> (Is the above phone number correct?)
            </button>
            <ResendCheckPhonePopup
              isOpen={this.state.isResetConfirmationOpen}
              value={phone}
              onClick={() => {
                this.setState({ isResetConfirmationOpen: false });
              }}
            />
          </>
        )}
        <CheckPhonePopup orderId={pendingOrder} value={phone} />
        <button>
          <a
            style={{
              textDecoration: 'none',
              color: palette.yellow,
              fontWeight: 100,
              paddingBottom: '2.5rem',
            }}
            href="mailto:info@boozapp.delivery"
            rel="noreferrer"
            target="_blank"
          >
            info@boozapp.delivery
          </a>
        </button>
      </Fragment>
    );
  }
}

export const CustomerOrder = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectSheet(anonymousOrder),
)(_CustomerOrder);
