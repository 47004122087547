//
// All values are stored in the localStorage, but expire with session cookie
//

// we can reuse google analitics session id '_gid'
// or replace it with our own cookie - handled by BE (initialization & expiration logic needed)
const cookieSessionKey = '_gid';

const getCookie = cname => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const setItem = (key, value) => {
  localStorage.setItem(cookieSessionKey, getCookie(cookieSessionKey));
  localStorage.setItem(key, value);
};

const getItem = key =>
  localStorage.getItem(cookieSessionKey) === getCookie(cookieSessionKey)
    ? localStorage.getItem(key)
    : undefined;

export const cookieControlledStorage = {
  setItem,
  getItem,
};
