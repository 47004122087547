import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || '';

export const initGA = () => {
  if (!GA_MEASUREMENT_ID) return;

  if (process.env.NODE_ENV === 'development') {
    ReactGA.initialize(GA_MEASUREMENT_ID, {
      debug: true,
      titleCase: false,
      gaOptions: {
        testMode: true,
      },
    });
  } else {
    ReactGA.initialize(GA_MEASUREMENT_ID);
  }
};

export const PageView = () => {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname + window.location.search,
  });
};

export const TrackView = () => {
  history.listen(location => {
    ReactGA.set({ page: location.pathname });

    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  });
};

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
export const Event = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};

export const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    ReactGA.set({
      page,
      ...options,
    });
    ReactGA.send({ hitType: 'pageview', page });
  };

  const HOC = class extends Component {
    componentDidMount() {
      const page = this.props.location.pathname + this.props.location.search;
      trackPage(page);
    }

    componentDidUpdate(prevProps) {
      const currentPage =
        prevProps.location.pathname + prevProps.location.search;
      const nextPage =
        this.props.location.pathname + this.props.location.search;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};
