import React, { useState, useMemo, useEffect } from 'react';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import DateIo from '@date-io/moment';
import injectSheet from 'react-jss';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { months } from './constnants';
import { generateOptions } from './utils';
import { media } from '../../../styles/theme';

const dateUtils = new DateIo();
const currentDate = dateUtils.date();

const styles = {
  container: {
    gap: 4,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [media.mobile]: {
      width: 'inherit',
      height: 'max-content',
      flexDirection: 'column',
    },
  },
  select: {
    outline: 'none',
    appearance: 'none',
    minWidth: '120px',
    height: '36px',
    backgroundColor: 'transparent',
    color: 'white!important',
    padding: '0 15px',
    border: '2px solid white',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    '&::before': {},
    '&::after': {
      content: 'none',
    },

    '& svg': {
      color: 'white',
    },
    [media.mobile]: {
      width: '100%',
      maxWidth: '85%',
      margin: '0 auto',
    },
  },
  option: {
    backgroundColor: 'white!important',
    color: 'black!important',
    padding: '5px',
    fontFamily: 'Roboto',
    fontSize: '1rem',
  },
};

const AppSelect = compose(injectSheet(styles))(({
  options = [],
  name,
  value,
  onChange,
  classes,
}) => {
  const handleChange = (e) => {
    const value = Number(e.target.value);

    onChange(name, value);
  };

  return (
    <Select variant={'outlined'} value={value} onChange={handleChange} className={classes.select}>
      {options.map(o => (
        <MenuItem key={o.value} value={o.value} className={classes.option}>{o.name}</MenuItem>
      ))}
    </Select>);
});

const minYear = 1980;

const defaultValue = {
  d: +dateUtils.getDayText(currentDate),
  m: dateUtils.getMonth(currentDate) + 1,
  y: dateUtils.getYear(currentDate),
};

const yearOptions = generateOptions(dateUtils.getYear(currentDate) - minYear + 1, minYear);

const AgeInput = ({ setDate, classes }) => {
  const [dateParts, setDateParts] = useState(defaultValue);

  const { d, m, y } = dateParts;

  const currentMonth = useMemo(() => months.find(month => month.value === m), [m]);

  const dayOptions = useMemo(() => generateOptions(Number(currentMonth.days)), [currentMonth]);

  const updateDateParts = (name, value) => {
    setDateParts(prevParts => {
      let d = prevParts.d;

      if (name === 'm') {
        const targetMonth = months.find(month => month.value === value);
        d = targetMonth && targetMonth.days < d ? targetMonth.days : d;
      }

      return ({
        ...prevParts,
        d,
        [name]: value,
      });
    });
  };

  useEffect(() => {
    setDate([m, d, y].join('/'));
  }, [m, d, y]);

  return (
    <div className={classes.container}>
      <AppSelect options={yearOptions} name={'y'} value={y} onChange={updateDateParts} />
      <AppSelect options={dayOptions} name={'d'} value={d} onChange={updateDateParts} />
      <AppSelect options={months} name={'m'} value={m} onChange={updateDateParts} />
    </div>
  );
};

export default compose(
  withNamespaces(),
  injectSheet(styles),
)(AgeInput);
