import React, { Fragment } from 'react';
import connect from 'react-redux/es/connect/connect';
import { push } from 'connected-react-router';
import { bindActionCreators, compose } from 'redux';
import injectSheet from 'react-jss';
import { DriverPersonalInfo } from 'ui/components/ecosystems/Driver/DriverPersonalInfo';
import { BackButton } from 'ui/components/atoms/Commons/Client/BackButton';
import { withContainer } from 'ui/hoc/withContainer';
import { withCommonLoading } from 'ui/hoc/withCommonLoading';
import { thunks } from '../../../../services/thunks';
import { DriverTopBar } from '../../molecules/Driver/DriverTopBar';
import { ProfileInventory } from 'ui/components/ecosystems/Driver/ProfileInventory';
import { profile } from 'ui/styles/Driver/Profile/profile';

const mapStateToProps = state => ({
  user: state.getIn(['app', 'user']),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDriverProfile: thunks.getDriverProfile,
      goToInventory: () => push('/inventory'),
    },
    dispatch,
  );

class _DriverProfile extends React.PureComponent {
  componentDidMount() {
    const { getDriverProfile, endLoading } = this.props;
    getDriverProfile(endLoading);
  }

  render() {
    const { classes, user, isLoading, goToInventory } = this.props;
    // if (isLoading) return null;
    return (
      <>
        <DriverTopBar showLogout />
        <BackButton from="/orderboard" />
        {isLoading ? (
          <div>Loading</div>
        ) : (
          <>
            <DriverPersonalInfo user={user} />
            <div className={classes.currentInventory}>Current inventory</div>
            <ProfileInventory inventory={user.get('inventory')} />
            <button
              type="button"
              onClick={goToInventory}
              className={classes.updateInventory}
            >
              Update inventory
            </button>
          </>
        )}
      </>
    );
  }
}

export const DriverProfile = compose(
  withContainer(),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withCommonLoading(),
  injectSheet(profile),
)(_DriverProfile);
