import { api } from 'services/api';
import { push } from 'connected-react-router';
import { setErrorToStore } from 'services/utils';

export const addProduct = (
  values,
  // setErrors,
  // setSubmitting,
) => async dispatch => {
  try {
    const response = await api.addProduct(values);
    console.log(response);
    dispatch(push('/admin/products'));
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
};
