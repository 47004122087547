import { media } from '../../theme';

export const productForm = {
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '5rem',
  },
  paper: {
    width: '700px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [media.mobile]: {
      width: '90%',
    },
    [media.desktop]: {
      width: '90%',
    },
  },
  input: {
    width: '80%',
    marginTop: '2rem',
  },
  uploadInput: {
    display: 'none',
  },
  buttons: {
    width: '80%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '2rem 0',
  },
  save: {
    width: '12rem',
    padding: '0.75rem 1.25rem',
    fontSize: '1rem',
    color: 'white',
    boxShadow: 'none',
    backgroundColor: '#03a9f4',
    '&:hover': {
      backgroundColor: '#2F80EC',
      boxShadow:
        '0px 1px 5px 0px rgba(0, 0, 0, 0.2),' +
        '0px 2px 2px 0px rgba(0, 0, 0, 0.14),' +
        '0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    },
  },
  cancel: {
    composes: '$save',
    backgroundColor: '#9e9e9e',
    '&:hover': {
      backgroundColor: '#757575',
    },
  },
  backError: {
    width: '100%',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '300',
    color: 'red',
  },
};
