import React, { useCallback, useRef } from 'react';
import { getIn } from 'formik';
import { TextField, withStyles } from '@material-ui/core';
import NumericInput from 'react-numeric-input';
import {
  styles,
  numericInputStyle,
} from '../../../../styles/Admin/commons/numberInput';
import clsx from 'clsx';

export const InputComponent = ({ onChange, onFocus, inputRef, ...rest }) => {
  const ref = useRef();

  const handleOnChange = useCallback(
    value => {
      if (onChange) {
        const {
          props: { required },
          state: { stringValue },
        } = ref.current;
        // allow empty if not required
        if (value === 0 && !required && stringValue === '') {
          value = '';
        }
        onChange({
          target: { value },
        });
      }
    },
    [onChange, ref],
  );

  const handleOnFocus = useCallback(
    e => {
      if (e.target.localName === 'input' && onFocus) {
        onFocus(e);
      }
    },
    [onFocus],
  );

  return (
    <NumericInput
      mobile={false}
      onChange={handleOnChange}
      onFocus={handleOnFocus}
      style={numericInputStyle}
      ref={x => {
        if (x) {
          ref.current = x;
        }
      }}
      {...rest}
    />
  );
};

class _FormikNumberInputField extends React.PureComponent {
  onChange = e => {
    const { form, field } = this.props;
    form.setFieldValue(field.name, e.target.value);
  };

  render() {
    const {
      form: { touched, errors },
      field: { value, name, onBlur },
      label,
      classes,
      className,
      disabled,
      InputProps,
      inputProps,
    } = this.props;
    const error = getIn(touched, name) && getIn(errors, name);

    return (
      <TextField
        error={!!error}
        id={name}
        value={value == null ? '' : value}
        label={error || label}
        variant="outlined"
        onChange={this.onChange}
        onBlur={onBlur}
        className={clsx(classes.root, className)}
        disabled={disabled}
        InputProps={{
          inputComponent: InputComponent,
          ...InputProps,
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={inputProps}
      />
    );
  }
}

export const FormikNumberInputField = withStyles(styles)(
  _FormikNumberInputField,
);
