import React from 'react';
import { createUseStyles } from 'react-jss';
import bannerBackground from '../../../images/good-time.jpg';
import { palette } from '../../../styles/theme';

export default ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.backstage}>
      <div className={classes.header}>
        <img src={bannerBackground} alt="bannerBackground" className={classes.img} />
        <div className={classes.content}>
          <h1>
            <span className={classes.highlighted}>Boo!{' '}
            Zapp!</span>
          </h1>
          <br/>
          <h2>
            After Hours{' '}
            <span className={classes.highlighted}>Alcohol&nbsp;Delivery</span>
          </h2>
          <br/>
          <h3>One moment please while we determine your delivery zone...</h3>
        </div>
      </div>
      {children}
    </div>
  );
};

const useStyles = createUseStyles({
  backstage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: palette.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  header: {
    position: 'absolute',
    width: '100%',
  },
  img: {
    width: '100%',
    maxHeight: '100vh',
    objectFit: 'cover',
    objectPosition: 'top left',
    filter: 'brightness(0.5)',
  },
  content: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: palette.white,
    fontFamily: 'Roboto',

    '& h1': {
      fontSize: 40,
    },
  },
  highlighted: {
    color: palette.yellow,
  },
});
