import React, { useState, useEffect, useMemo } from 'react';
import { Field } from 'formik';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import injectSheet from 'react-jss';
import { PriceQuantityField } from './PriceQuantityField';
import { TextField, MenuItem } from '@material-ui/core';
import { thunks } from '../../../../../services/thunks';
import { getDeliveryZone } from '../../../../../selectors/getDeliveryZone';
import { productPriceField } from 'ui/styles/Admin/AddProduct/productPriceField';
import clsx from 'clsx';
import { useCallback } from 'react';
import { PriceInputField } from './PriceInputField';

const mapStateToProps = state => ({
  deliveryZones: getDeliveryZone(state),
});

const mapDispatchToProps = dispatch => ({
  getDeliveryZones: x => dispatch(thunks.getDeliveryZone(x)),
});

const _ProductPriceField = ({
  field: { value, name },
  form: { touched, errors, setFieldValue },
  isFixedPrice,
  classes,
  getDeliveryZones,
  deliveryZones,
}) => {
  const [addedZones] = useState(new Set());
  const [nextZone, setNextZone] = useState();
  useEffect(() => {
    getDeliveryZones();
  }, []);
  const [usedZones, unusedZones] = useMemo(
    () => {
      if (Object.keys(value).length === 0) {
        addedZones.clear();
      }
      return [
        deliveryZones
          .filter(
            x =>
              value[x.value] &&
              Object.values(value[x.value]).some(x => !x.disabled) &&
              !addedZones.has(x.value),
          )
          .concat(
            Array.from(addedZones, x => ({
              value: x,
              label: deliveryZones.filter(y => y.value === x)[0].label,
            })),
          ),
        deliveryZones.filter(
          x =>
            (!value[x.value] ||
              Object.values(value[x.value]).every(x => x.disabled)) &&
            !addedZones.has(x.value),
        ),
      ];
    },
    [deliveryZones, addedZones, value],
  );
  useEffect(
    () => {
      if (unusedZones.length && !unusedZones.find(x => x.value === nextZone)) {
        setNextZone(unusedZones[0].value);
      }
    },
    [unusedZones, setNextZone],
  );
  const addZone = useCallback(
    () => {
      let prices = value[nextZone];
      if (prices) {
        Object.values(prices).forEach(x => (x.disabled = false));
      }
      setFieldValue(`${name}[${nextZone}]`, prices || {});
      addedZones.add(nextZone);
    },
    [setFieldValue, name, addedZones, nextZone, value],
  );
  const removeZone = useCallback(
    key => {
      let prices = value[key];
      if (prices) {
        Object.entries(prices).forEach(([key, price]) => {
          if (price.value) {
            price.disabled = true;
          } else {
            delete prices[key];
          }
        });
      }
      setFieldValue(`${name}[${key}]`, prices);
      addedZones.delete(key);
    },
    [setFieldValue, name, addedZones, value],
  );
  const PriceComponent = isFixedPrice ? PriceInputField : PriceQuantityField;

  return (
    <div className={classes.container}>
      {usedZones.map(({ value, label }) => (
        <div key={value} className={classes.row}>
          <TextField
            label="Delivery Zone"
            name={`zone_${name}[${value}]`}
            InputProps={{
              readOnly: true,
            }}
            value={label}
            variant="outlined"
            className={classes.zone}
          />
          <Field
            label="Price"
            name={isFixedPrice ? `${name}[${value}][1]` : `${name}[${value}]`}
            component={PriceComponent}
            className={classes.input}
          />
          <IconButton
            onClick={() => removeZone(value)}
            className={classes.deleteRow}
          >
            <DeleteIcon className={classes.delete} />
          </IconButton>
        </div>
      ))}
      {unusedZones.length > 0 && (
        <div key={nextZone} className={classes.row}>
          <TextField
            label="Delivery Zone"
            select
            value={nextZone || ''}
            onChange={e => setNextZone(e.target.value)}
            variant="outlined"
            className={classes.zone}
          >
            {unusedZones.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <Button
            variant="contained"
            color="default"
            className={clsx(classes.add, classes.input)}
            onClick={addZone}
          >
            Add Price
            <AddIcon />
          </Button>
        </div>
      )}
    </div>
  );
};

export const ProductPriceField = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectSheet(productPriceField),
)(_ProductPriceField);
