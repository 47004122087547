import React, { Fragment } from 'react';
import injectSheet from 'react-jss';
import { compose } from 'redux';
import { AppTopBar } from 'ui/components/ecosystems/Customer/AppTopBar';
import { BackButton } from 'ui/components/atoms/Commons/Client/BackButton';
import { withContainer } from 'ui/hoc/withContainer';
import { terms } from 'ui/styles/Driver/Terms/terms';

const _Help = ({ classes }) => (
  <Fragment>
    <AppTopBar login signup />
    <BackButton from="/" />
    <div className={classes.container}>
      <h1>Help and FAQ</h1>
      <p>
        <strong>Can I just phone in my order?</strong>
        <br />
        Sorry, you must place the order yourself online, just like Uber (as
        opposed to calling a cab).
      </p>
      <p>
        <strong>Do I pay online?</strong>
        <br />
        No, you pay the driver directly when he arrives, normally with cash. For
        e-transfers contact the driver <strong>after</strong> your order has
        been accepted.
      </p>
      <p>
        <strong>Who do I E-Transfer to?</strong>
        <br />
        The driver. Once your order has been accepted you can call the driver
        using the "Call Driver" button to get details.
      </p>
      <p>
        <strong>I placed an order and nothing happened.</strong>
        <br />
        Did you receive a confirmation text? After you fill out the order form
        and tap the "Order" button you will receive a text to your phone to
        confirm the order. If you <strong>did not</strong> receive a text it is
        probably because you entered the wrong phone number. Please check that
        the phone number is correct.
      </p>
      <p>
        <strong>
          I replied to the confirmation text and nothing happened.
        </strong>
        <br />
        Did you tap on the link in the text? This will take you to the live
        order page in your web browser. DO NOT reply directly to the text (with
        an "OK" or whatever) -- that will not work. You must tap on the link.
      </p>
      <p>
        <strong>I confirmed my order, now what?</strong>
        <br />
        After you confirm, the drivers in your zone will be notified. You will
        receive a text when one of them accepts your order. If you do not
        receive an acceptance notice right away, that is because our drivers are
        busy and will accept the order shortly.
      </p>
      <p>
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
        <a id="stuck" />
        <strong>Help! I'm stuck on my last order page.</strong>
        <br />
        This usually happens when your last order was canceled. We're working on
        fixing this bug. To get to the menu try clearing the cookie for the site
        "boozapp.delivery" and then tapping on "Boo Zapp" at the top left of the
        page. If that brings you back to the order page just tap "Boo Zapp"
        again or reload the page. If that doesn't work, please call us at{' '}
        <span style={{ color: 'blue' }}>
          <a href="tel:365-657-4719">(365) 657-4719</a>
        </span>
        . Here are instruction for clearing cookies in{' '}
        <span style={{ color: 'blue' }}>
          <a href="https://support.apple.com/en-us/HT201265">
            Safari browser (iPhone)
          </a>
        </span>
        . Here are instructions for{' '}
        <span style={{ color: 'blue' }}>
          <a href="https://support.google.com/accounts/answer/32050?hl=en&co=GENIE.Platform%3DAndroid">
            Chrome browser (iPhone or Android)
          </a>
        </span>
        .
      </p>
      <p>
        <strong>How long will the delivery take?</strong>
        <br />
        Anywhere between 15-60 minutes, depending upon how busy our drivers are.
        You can contact the driver for an ETA.
      </p>
      <p>
        <strong>Is there a delivery charge?</strong>
        <br />
        No, prices are as stated on menu and order summary.
      </p>
      <p>
        <strong>I'm having trouble filling out the order form</strong>
        <br />
        Make sure all the required fields are filled. Scroll up on the page to
        see if you missed anything. If the form will not accept your address
        make sure your address is in the zone you chose i.e. You are in Vaughan
        but chose Richmond Hill as your zone. If nothing seems to work you can
        call{' '}
        <span style={{ color: 'blue' }}>
          <a href="tel:365-657-4719">(365) 657-4719</a>
        </span>{' '}
        for further assistance.
      </p>
      <p>
        <strong>I'm getting an error message when I try to order</strong>
        <br />
        This can happen when you switch back and forth between the menu and
        order pages too many times (our bad, we will get this fixed). Try
        reloading from the menu page and starting over (sorry). If that doesn't
        work you can call{' '}
        <span style={{ color: 'blue' }}>
          <a href="tel:365-657-4719">(365) 657-4719</a>
        </span>{' '}
        for further assistance.
      </p>
      <p>
        <strong>Still need help?</strong> Call{' '}
        <span style={{ color: 'blue' }}>
          <a href="tel:365-657-4719">(365) 657-4719</a>
        </span>
      </p>
    </div>
  </Fragment>
);

export const Help = compose(
  withContainer(),
  injectSheet(terms),
)(_Help);
