
const styles = () => ({
  datepicker: {
    display: 'inline-block',
    padding: '4px',
    position: 'relative',
    top: '-6px',
  },
});

export default styles;
