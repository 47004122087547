import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { thunks } from 'services/thunks';
import { Switch, Route } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { StripeProvider } from 'react-stripe-elements';
import { DriverRoute } from 'ui/hoc/DriverRoute';
import { CustomerRoute } from 'ui/hoc/CustomerRoute';
import { ErrorModal } from 'ui/components/molecules/Commons/Client/ErrorModal';
import { CheckAge } from 'ui/components/environments/General/CheckAge';
import { Underage } from 'ui/components/environments/General/Underage';
import { Main } from 'ui/components/environments/Customer/Main';
import { Login } from 'ui/components/environments/General/Login';
import { SignUp } from 'ui/components/environments/General/SignUp';
import { SetNewPassword } from 'ui/components/environments/General/SetNewPassword';
import { PageNotFound } from 'ui/components/environments/General/PageNotFound';
import { CustomerProfile } from 'ui/components/environments/Customer/CustomerProfile';
import { DriverPromoPage } from 'ui/components/environments/Driver/DriverPromoPage';
import { Inventory } from 'ui/components/environments/Driver/Inventory';
import { DriverProfile } from 'ui/components/environments/Driver/DriverProfile';
import { AdminShell } from 'ui/components/environments/Admin/AdminShell';
import { OrderBoard } from 'ui/components/environments/Driver/OrderBoard';
import { CompleteOrder } from 'ui/components/environments/Customer/CompleteOrder';
import { SingleOrderPage } from 'ui/components/environments/Customer/SingleOrderPage';
import { ConfirmOrder } from 'ui/components/environments/Customer/ConfirmOrder';
import { DriverOrder } from 'ui/components/environments/Driver/DriverOrder';
import { Terms } from 'ui/components/environments/Customer/Terms';
import { Help } from 'ui/components/environments/Customer/Help';
import { DriverTerms } from 'ui/components/environments/Driver/DriverTerms';
import { ThemeProvider } from 'react-jss';
import { withTracker, PageView, initGA } from 'ui/components/tracking';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiButton: {
      contained: {
        color: 'white',
        backgroundColor: '#2196f3',
        '&:hover': {
          backgroundColor: '#2b52f3',
        },
      },
    },
  },
});

class _App extends Component {
  componentDidMount() {
    this.props.checkAuth();
    initGA();
    PageView();
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
              <ConnectedRouter history={this.props.history}>
                <Switch>
                  <CustomerRoute exact path="/" isCheckAge component={Main} />
                  <CustomerRoute exact path="/oshawa" isCheckAge component={Main} />
                  <CustomerRoute exact path="/hamilton" isCheckAge component={Main} />
                  <CustomerRoute exact path="/kitchener" isCheckAge component={Main} />
                  <CustomerRoute exact path="/niagarafalls" isCheckAge component={Main} />
                  <CustomerRoute exact path="/burlington" isCheckAge component={Main} />
                  <CustomerRoute exact path="/oakville" isCheckAge component={Main} />
                  <CustomerRoute exact path="/waterloo" isCheckAge component={Main} />
                  <CustomerRoute exact path="/barrie" isCheckAge component={Main} />
                  <CustomerRoute exact path="/london" isCheckAge component={Main} />
                  <Route
                    exact
                    path="/check-age"
                    component={withTracker(CheckAge)}
                  />
                  <Route
                    exact
                    path="/underage"
                    component={withTracker(Underage)}
                  />
                  <Route exact path="/login" component={withTracker(Login)} />
                  <Route exact path="/signup" component={withTracker(SignUp)} />
                  <Route
                    exact
                    path="/customer/terms"
                    component={withTracker(Terms)}
                  />
                  <Route
                    exact
                    path="/customer/help"
                    component={withTracker(Help)}
                  />
                  <Route
                    exact
                    path="/driver/terms"
                    component={withTracker(DriverTerms)}
                  />
                  <Route
                    exact
                    path="/account/resetPassword"
                    component={withTracker(SetNewPassword)}
                  />
                  <Route
                    exact
                    path="/become-a-driver"
                    component={withTracker(DriverPromoPage)}
                  />
                  <CustomerRoute
                    exact
                    path="/complete-order"
                    component={withTracker(CompleteOrder)}
                  />
                  <CustomerRoute
                    exact
                    path="/order/confirm/:id"
                    component={withTracker(ConfirmOrder)}
                  />
                  <CustomerRoute
                    exact
                    path="/order/:id"
                    component={withTracker(SingleOrderPage)}
                  />
                  <CustomerRoute
                    exact
                    path="/customer/profile"
                    onlyFor="customer"
                    component={withTracker(CustomerProfile)}
                  />
                  <DriverRoute
                    exact
                    path="/inventory"
                    component={withTracker(Inventory)}
                  />
                  <DriverRoute
                    exact
                    path="/orderboard"
                    component={withTracker(OrderBoard)}
                  />
                  <DriverRoute
                    exact
                    path="/driver/order/:id"
                    component={withTracker(DriverOrder)}
                  />
                  <DriverRoute
                    exact
                    path="/driver/profile"
                    component={withTracker(DriverProfile)}
                  />
                  <AdminShell />
                  <Route path="*" component={withTracker(PageNotFound)} />
                </Switch>
              </ConnectedRouter>
            </StripeProvider>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
        <ErrorModal />
      </ThemeProvider>
    );
  }
}

export const App = connect(
  null,
  {
    checkAuth: thunks.checkAuth,
  },
)(_App);
