import React from 'react';
import { compose } from 'redux';
import { withStyles, TableRow, TableCell } from '@material-ui/core';
import { productRow } from 'ui/styles/Admin/Products/productRow';
import { RowActions } from 'ui/components/molecules/Admin/Products/RowActions';
import { getProductRowPrice } from 'selectors/getProductRowPrice';
import { InfoIconWithTooltip } from 'ui/components/atoms/Commons/Admin/InfoIconWithTooltip';
import { TooltipPriceList } from 'ui/components/atoms/Admin/Products/TooltipPriceList';

const _ProductRow = ({
  item: { id, name, order, category, productPrices },
  classes,
}) => {
  const price = getProductRowPrice(productPrices);
  const { pricesWrapper, ...tooltipClasses } = classes;
  return (
    <TableRow>
      <TableCell>{order}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{category}</TableCell>
      <TableCell>
        <div className={pricesWrapper}>
          <span>{price}</span>
          {price != null && (
            <InfoIconWithTooltip
              classes={tooltipClasses}
              tooltipMessage={<TooltipPriceList prices={productPrices} />}
            />
          )}
        </div>
      </TableCell>
      <RowActions id={id} name={name} />
    </TableRow>
  );
};

export const ProductRow = compose(withStyles(productRow))(_ProductRow);
