export const months = [
  {
    name: "January",
    short: "Jan",
    value: 1,
    days: 31
  },
  {
    name: "February",
    short: "Feb",
    value: 2,
    days: 28
  },
  {
    name: "March",
    short: "Mar",
    value: 3,
    days: 31
  },
  {
    name: "April",
    short: "Apr",
    value: 4,
    days: 30
  },
  {
    name: "May",
    short: "May",
    value: 5,
    days: 31
  },
  {
    name: "June",
    short: "Jun",
    value: 6,
    days: 30
  },
  {
    name: "July",
    short: "Jul",
    value: 7,
    days: 31
  },
  {
    name: "August",
    short: "Aug",
    value: 8,
    days: 31
  },
  {
    name: "September",
    short: "Sep",
    value: 9,
    days: 30
  },
  {
    name: "October",
    short: "Oct",
    value: 10,
    days: 31
  },
  {
    name: "November",
    short: "Nov",
    value: 11,
    days: 30
  },
  {
    name: "December",
    short: "Dec",
    value: 12,
    days: 31
  }
]
