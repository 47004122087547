import { media } from '../../theme';

export const addDeliveryZoneForm = {
  backstage: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(225, 225, 225, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    width: '700px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    boxShadow: '0 11px 32px 2px rgba(0,0,0,0.1)',
    zIndex: 10,
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    textAlign: 'center',
    textTransform: 'uppercase',
    [media.desktop]: {
      margin: '3rem 0',
      fontSize: '1.5rem',
    },
  },
  form: {
    width: '70%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  row: {
    width: '100%',
    marginTop: '2rem',
  },
  header: {
    cursor: 'pointer !important',

    '& > div': {
      cursor: 'pointer !important',
    }
  },
  input: {
    width: '100%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: '2rem 0',
    width: '100%',
  },
  cancel: {

  },
  submit: {
    padding: '0.75rem 3rem',
    fontSize: '1rem',
  },
  backError: {
    width: '100%',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '300',
    color: 'red',
  },

};
