import React from 'react';
import { compose } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { ChooseAreaModal } from 'ui/components/organisms/Customer/Products/ChooseAreaModal';
import { EmailWhenAreaSupported } from 'ui/components/organisms/Customer/Products/EmailWhenAreaSupported';
import { ThxForSubscribe } from 'ui/components/organisms/Customer/Products/ThxForSubscribe';
import { thunks } from 'services/thunks';
import Backstage from '../components/environments/Customer/Backstage';

const mapStateToProps = state => ({
  choseAreaModal: state.getIn(['app', 'modals', 'chooseArea']),
  emailModal: state.getIn(['app', 'modals', 'emailWhenAreaSupported']),
  thxForSubscribe: state.getIn(['app', 'modals', 'thxForSubscribe']),
  deliveryZoneId: state.getIn(['app', 'user', 'deliveryZoneId']),
});

const mapDispatchToProps = {
  detectDeliveryZone: thunks.detectDeliveryZone,
};

export const withChooseArea = () => WrappedComponent => {
  class WithChooseArea extends React.PureComponent {
    componentDidMount() {
      this.props.detectDeliveryZone();
    }

    render() {
      const {
        choseAreaModal,
        emailModal,
        thxForSubscribe,
        deliveryZoneId,
      } = this.props;
      if (choseAreaModal) {
        return (
          <Backstage>
            <ChooseAreaModal />
          </Backstage>
        );
      }
      if (emailModal) {
        return (
          <Backstage>
            <EmailWhenAreaSupported />
          </Backstage>
        );
      }
      if (thxForSubscribe) {
        return (
          <Backstage>
            <ThxForSubscribe />
          </Backstage>
        );
      }
      if (deliveryZoneId) {
        return <WrappedComponent {...this.props} />;
      }
      return <Backstage />;
    }
  }

  return compose(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    ),
  )(WithChooseArea);
};
