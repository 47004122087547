import React from 'react';
import { createPortal } from 'react-dom';
import Select from 'react-select';
import { compose } from 'redux';
import connect from 'react-redux/es/connect/connect';
import injectSheet from 'react-jss';
import { thunks } from '../../../../../services/thunks';
import { selectors } from '../../../../../selectors';
import { chooseAreaModal } from '../../../../styles/Customer/CustomerHome/chooseAreaModal';
import {
  dropDownStyles,
  dropDownTheme,
} from '../../../../styles/Customer/CustomerHome/dropDown';
import { actions } from '../../../../../store/actions';

const mapStateToProps = state => ({
  deliveryZones: selectors.getChooseAreaDropDownData(state),
  postalCode: state.getIn(['app', 'notification', 'chooseArea']),
});

const mapDispatchToProps = dispatch => ({
  getDeliveryZone: x => dispatch(thunks.getDeliveryZone(x)),
  handleDeliveryZoneChoice: x => dispatch(thunks.handleDeliveryZoneChoice(x)),
  emailWhenAreaSupported: () =>
    dispatch(actions.openModal.act('emailWhenAreaSupported')),
});

class _ChooseAreaModal extends React.Component {
  state = {
    loading: true,
    value: '',
  };

  componentDidMount() {
    this.props.getDeliveryZone(this.endLoading);
  }

  endLoading = () => this.setState({ loading: false });

  onChangeDropDown = value => this.setState({ value });

  handleDeliveryZoneChoice = () => {
    this.props.handleDeliveryZoneChoice(this.state.value);
  };

  emailWhenAreaSupported = () => {
    this.props.emailWhenAreaSupported();
  };

  render() {
    const { classes, deliveryZones } = this.props;
    const { loading } = this.state;
    return createPortal(
      <div className={classes.backstage}>
        <div className={classes.container}>
          <p className={classes.message}>
            (Sorry, Boo Zapp couldn't find your zone)
          </p>
          <h1 className={classes.title}>Please choose your area</h1>
          <Select
            onChange={this.onChangeDropDown}
            isLoading={loading}
            isSearchable={false}
            options={deliveryZones}
            value={this.state.value}
            placeholder="Select area"
            theme={dropDownTheme}
            styles={dropDownStyles}
          />
          <button
            type="button"
            onClick={this.emailWhenAreaSupported}
            className={classes.link}
          >
          <br/>
            Email me when Boo Zapp is in my area
          </button>
          <button
            type="button"
            onClick={this.handleDeliveryZoneChoice}
            className={classes.ok}
          >
            Ok
          </button>
        </div>
      </div>,
      document.getElementById('modal'),
    );
  }
}

export const ChooseAreaModal = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectSheet(chooseAreaModal),
)(_ChooseAreaModal);
