import React, { Component } from 'react';

import { withStyles } from '@material-ui/core';
import CalendarTodayRounded from '@material-ui/icons/CalendarTodayRounded';

import { DatePicker } from '../../../atoms/Commons/Admin/DatePicker';

import styles from '../../../../styles/Admin/commons/pickerForm';

class _DatePickerForm extends Component {
  // shouldComponentUpdate(nextProps) {
  //   const { value } = this.props;
  //
  //   return nextProps.value !== value;
  // }

  render() {
    const {
      // handleSearch,
      // value,
      // onChange,
      classes,
      // autoFocus,
      onChangeFrom,
      onChangeTo,
    } = this.props;

    return (
      <div className={classes.wrap}>
        <div className={classes.searchWrap}>
          <DatePicker id="from" label="from" onChange={onChangeFrom} />
          <DatePicker id="to" label="to" onChange={onChangeTo} />
          <CalendarTodayRounded className={classes.icon} />
        </div>
      </div>
    );
  }
}

export const DatePickerForm = withStyles(styles)(_DatePickerForm);
