import { palette } from '../../theme';

const styles = () => ({
  icon: {
    width: 20,
    height: 20,
    color: palette.blue,
    display: 'inline-block',
  },
  nameBlock: {
    display: 'inline-block',
  },
  status: {
    color: 'red',
    marginTop: '10px',
    fontSize: '10px',
  },
  name: {
    margin: '5px auto',
    textAlign: 'left',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  lightTooltip: {
    backgroundColor: palette.white,
    color: palette.black,
    fontSize: 14,
    boxShadow: '1px 2px 10px 1px rgba(0,0,0,0.75)',
  },
  popper: {
    opacity: 1,
  },
  iconBlock: {
    position: 'relative',
    margin: '10px 0',
    left: '-15px',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  nameBlockWithMargin: {
    display: 'inline-block',
    textAlign: 'center',
    marginLeft: '20px',
  },
  new: {
    color: '#03d403',
    fontSize: '12px',
    position: 'relative',
    top: '-1rem',
    left: 0,
  },
  onlineStatus: {
    marginLeft: '0.5rem',
  },
  onlineIcon: {
    color: 'green',
    fontSize: '14px',
    display: 'inline-block',
  },
});

export default styles;
