import React, { Fragment, useEffect } from 'react';
import { compose } from 'redux';
import { withChooseArea } from '../../../hoc/withChooseArea';
import { withContainer } from '../../../hoc/withContainer';
import { AppTopBar } from '../../ecosystems/Customer/AppTopBar';
import { CustomerDeliveryZone } from 'ui/components/atoms/Customer/Products/CustomerDeliveryZone';
import { Products } from '../../ecosystems/Customer/Products';
import { OrderSummary } from '../../ecosystems/Customer/OrderSummary';
import { ScrollTo } from '../../atoms/Customer/Products/ScrollTo';
import { palette } from 'ui/styles/theme';
import { withTracker } from 'ui/components/tracking';
import Editor from '../../organisms/Commons/RichText/Editor';
import defaultHeader from '../../../constants/defaultHeader';
import { connect } from 'react-redux';
import { getDeliveryZoneHeader } from '../../../../services/thunks/general/getDeliveryZoneHeader';
import { cookieControlledStorage } from '../../../../utils';

const mapStateToProps = state => ({
  header: state.getIn(['app', 'currentDeliveryZoneHeader']) || defaultHeader,
});

const mapDispatchToProps = { getDeliveryZoneHeader };

const _Main = ({ history, getDeliveryZoneHeader, header }) => {
  useEffect(() => {
    const zoneId = cookieControlledStorage.getItem('deliveryZoneId');
    getDeliveryZoneHeader(zoneId);
  }, []);

  return (
    <Fragment>
      <AppTopBar becomeDriver login signup />
      <Editor value={header} readOnly={true} />
      <CustomerDeliveryZone />
      <Products history={history} />
      <ScrollTo />
      <div id="orderList">
        <OrderSummary />
      </div>

      <button>
        <span style={{ fontSize: '9pt' }}>
          (Must be 19 or older. Driver reserves right to refuse order.)
        </span>
        <br />
        <span style={{ fontSize: '14px' }}>
          Need Help?{' '}
          <span style={{ color: 'blue' }}>
            <a href="/customer/help">Go here</a>
          </span>
          <br />{' '}
        </span>
        <br />
        <br />
        <a
          style={{
            textDecoration: 'none',
            color: palette.yellow,
            fontWeight: 500,
            paddingBottom: '100%',
          }}
          href="mailto:info@boozapp.delivery"
          rel="noreferrer"
          target="_blank"
        >
          {' '}
          info@boozapp.delivery
        </a>
      </button>
    </Fragment>
  );
};

export const Main = compose(
  withChooseArea(),
  withContainer(),
  withTracker,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(_Main);
