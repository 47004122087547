import { button } from '../../Commons/button';
import { media } from '../../theme';

export const orderSummary = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: '1.75rem',
    fontWeight: 500,
    textTransform: 'uppercase',
    [media.mobile]: {
      margin: '2rem 0',
      fontSize: '1.5rem',
    },
    [media.desktop]: {
      margin: '2rem',
      fontSize: '1.75rem',
    },
  },
  itemRow: {
    width: '60%',
    display: 'grid',
    gridTemplateRows: 'auto',
    gridTemplateColumns: '10% 40% 20% 30%',
    lineHeight: '2rem',
    fontWeight: 300,
    '& > span:last-child': {
      justifySelf: 'end',
    },
    [media.mobile]: {
      width: '100%',
    },
    [media.desktop]: {
      width: '100%',
    },
  },
  paymentRow: {
    composes: '$itemRow',
    marginTop: '1rem',
    lineHeight: '1.5rem',
    width: '100%',
    [media.mobile]: {
      width: '100%',
    },
  },
  payment: {
    gridArea: '2 / 1 / 2 / 5',
    display: 'flex',
    justifySelf: 'center',
    marginTop: '1rem',
    '& > span': {
      color: '#E0B317',
      fontWeight: 500,
    },
    [media.mobile]: {
      gridArea: '2 / 1 / 2 / 5',
      display: 'flex',
      justifySelf: 'center',
      marginTop: '2rem',
    },
  },
  total: {
    gridArea: '1 / 1 / 1 / 5',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0',
    // padding: '0 5%',
    backgroundColor: '#F3F3F3',
    '& > span:first-child': {
      fontWeight: 500,
    },
    padding: '1rem 10%',
    [media.mobile]: {
      gridArea: '1 / 1 / 1 / 5',
      margin: '0',
      padding: '1rem 10%',
            width: '80%',

    },
  },
  submit: {
    ...button.yellow,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '2rem auto 1rem auto',
  },
};
