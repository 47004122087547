import { button } from '../../Commons/button';
import { media, palette } from '../../theme';

export const appTopBar = {
  container: {
    width: '100vw',
    height: '6rem',
    display: 'grid',
    gridTemplateColumns: '5% 45% 45% 5%',
    gridTemplateRows: '60% 40%',
    gridTemplateAreas: `
      '. a c .'
      'b b b b'
    `,
    backgroundColor: 'white',
    [media.desktop]: {
      height: '5.5rem',
      gridTemplateColumns: 'auto 1030px auto',
      gridTemplateRows: '100%',
      gridTemplateAreas: `
      '. a .'
    `,
    },
  },
  logo: {
    gridArea: 'a',
    alignSelf: 'center',
    fontSize: '1.125rem',
    fontFamily: 'Righteous',

    textTransform: 'uppercase',
    cursor: 'pointer',
    [media.desktop]: {
      width: '10rem',
      fontSize: '1.8rem',
    },
  },
  profileLink: {
    gridArea: 'a',
    justifySelf: 'end',
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontFamily: 'Roboto',
    fontSize: '1.375rem',
    fontWeight: 300,
    [media.mobile]: {
      gridArea: 'c',
      fontSize: '1rem',
      textAlign: 'end',
    },
  },
  profileIcon: {
    fill: 'white',
    flexShrink: 0,
    '& circle': {
      fill: palette.yellow,
    },
    [media.mobile]: {
      height: '2.5rem',
      marginLeft: '0.5rem',
    },
    [media.desktop]: {
      height: '3.25rem',
      marginLeft: '1rem',
    },
  },
  buttons: {
    gridArea: 'a',
    height: '100%',
    width: '100%',
    justifySelf: 'end',
    alignSelf: 'center',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '100%',
    gridTemplateAreas: `
      'a'
    `,
    [media.desktop]: {
      display: 'block',
    },
  },
  becomeDriver: {
    gridArea: 'b',
    // width: '100%',
    // height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: `2px solid ${palette.yellow}`,
    fontFamily: 'Montserrat',
    fontSize: '1rem',
    fontWeight: 600,
    color: palette.yellow,
    textTransform: 'uppercase',
    // textDecoration: 'underline',
    // textUnderlinePosition: 'under',
    width: 'unset',
    height: 'unset',
    border: 'none',
    textDecoration: 'none',
    [media.desktop]: {
      border: 'none',
      gridArea: 'a',
      // width: '20%',
      justifySelf: 'end',
      // marginRight: '30%',
      // height: '15%',
      marginRight: '10%',
    },
  },
  loginLink: {
    alignSelf: 'center',
    justifySelf: 'end',
    textDecoration: 'none',
    marginRight: 0,
    [media.desktop]: {
      gridArea: 'a',
      // marginRight: '16%',
    },
    [media.mobile]: {
      gridArea: 'c',
      // marginRight: ({ signup }) => (signup ? '6rem' : 0),
    },
  },
  login: {
    ...button.white,
    // width: '88px',
    color: palette.black,
    borderColor: palette.black,
    width: '100%',
    height: '100%',
    border: 'none',
    [media.desktop]: {
      ...button.white[media.desktop],
      // width: '8.125rem',
      fontWeight: 600,
      marginLeft: '2.5rem',
      width: '100%',
      height: '100%',
    },
  },
  signUpLink: {
    gridArea: 'c',
    alignSelf: 'center',
    justifySelf: 'end',
    [media.desktop]: {
      gridArea: 'a',
    },
  },
  signUp: {
    ...button.white,
    color: 'black',
    borderColor: 'black',
    width: '88px',
    fontWeight: 600,
    [media.desktop]: {
      ...button.white[media.desktop],
      width: '8.125rem',
      color: 'white',
      backgroundColor: palette.yellow,
      borderColor: palette.yellow,
    },
  },
  logout: {
    ...button.white,
    gridArea: 'c',
    alignSelf: 'center',
    justifySelf: 'end',
    color: 'black',
    borderColor: 'black',
    width: '88px',
    fontWeight: 600,
    [media.desktop]: {
      ...button.white[media.desktop],
      width: '8.125rem',
      gridArea: 'a',
    },
  },
};
