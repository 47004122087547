import React from 'react';
import { compose } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { AnonymousOrder } from '../../ecosystems/Customer/AnonymousOrder';
import { CustomerOrder } from '../../ecosystems/Customer/CustomerOrder';
import { AppTopBar } from '../../ecosystems/Customer/AppTopBar';
import { BackButton } from '../../atoms/Commons/Client/BackButton';
import { withContainer } from 'ui/hoc/withContainer';
import ScrollToTop from 'ui/components/environments/Customer/ScrollToTop';

const mapStateToProps = state => ({
  isAuth: state.getIn(['app', 'isAuth']),
  role: state.getIn(['app', 'user', 'role']),
  orderList: state.getIn(['app', 'orderList']),
});

const _CompleteOrder = ({ isAuth, role }) => (
  <>
    <ScrollToTop />
    <AppTopBar becomeDriver login signup />
    <BackButton from="/" />
    {isAuth && role === 'customer' ? <CustomerOrder /> : <AnonymousOrder />}
  </>
);

export const CompleteOrder = compose(
  connect(mapStateToProps),
  withContainer(),
)(_CompleteOrder);
