import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  role: state.getIn(['app', 'user', 'role']),
  isSuspended: state.getIn(['app', 'user', 'suspended']),
  activeOrder: state.getIn(['app', 'activeOrder']),
});

const _DriverRoute = ({ role, isSuspended, activeOrder, ...props }) => {
  // const routeState = get(props, ['location', 'state'], {});
  if (activeOrder && props.path !== '/driver/order/:id') {
    return <Redirect to={`/driver/order/${activeOrder}`} />;
  }
  if (role === 'driver' && isSuspended && props.path !== '/driver/profile')
    return <Redirect to="/driver/profile" />;
  /*
  if (routeState.blockRouter && props.path !== '/inventory') {
    return (
      <Redirect to={{ pathname: '/inventory', state: { blockRouter: true } }} />
    );
  }
*/
  if (role === 'driver') return <Route {...props} />;
  if (role === 'customer') return <Redirect to="/" />;
  if (role === 'admin') return <Redirect to="/admin/drivers" />;
  return <Redirect to="/" />;
};

export const DriverRoute = connect(mapStateToProps)(_DriverRoute);
