import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import injectSheet from 'react-jss';
// import { NavLink } from 'react-router-dom';
import { CheckPhonePopup } from 'ui/components/organisms/Customer/CompleteOrder/CheckPhonePopup';
import { AnonymousAddressForm } from '../../organisms/Customer/CompleteOrder/AnonymousAddressForm';
import { OrderSummaryList } from '../../organisms/Customer/CompleteOrder/OrderSummaryList';
import { anonymousOrder } from '../../../styles/Customer/CompleteOrder/anonymousOrder';
import { thunks } from '../../../../services/thunks';
import { ResendCheckPhonePopup } from 'ui/components/organisms/Customer/CompleteOrder/ResendCheckPhonePopup';
import { palette } from 'ui/styles/theme';
import { Event } from '../../tracking';

const mapStateToProps = state => ({
  pendingOrder: state.getIn(['app', 'pendingOrder']),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      sendAnonymousOrder: thunks.sendAnonymousOrder,
      geocodeAddress: thunks.geocodeAddress,
      resendLink: thunks.resendLink,
    },
    dispatch,
  );

class _AnonymousOrder extends React.Component {
  resendLink = () => {
    const { resendLink, pendingOrder } = this.props;
    this.setState({ isResetConfirmationOpen: true });
    resendLink(pendingOrder);
  };

  constructor(props) {
    super(props);
    this.state = { phone: '', isResetConfirmationOpen: false };
    this.onChange = this.handlePhoneChange.bind(this);
    this.sendAnonymousOrder = this.sendAnonymousOrder.bind(this);
  }

  handlePhoneChange(e) {
    if (e.target.id === 'phone') {
      this.setState({ phone: e.target.value });
    }
  }

  sendAnonymousOrder(values, setErrors, setSubmitting) {
    this.setState({ phone: values.phone });

    Event('User', 'conversion_event_purchase"', 'Conversion Event Purchase"');

    this.props.sendAnonymousOrder(values, setErrors, setSubmitting);
  }

  render() {
    const {
      classes,
      pendingOrder,
      geocodeAddress,
      phoneValue = this.state.phone,
    } = this.props;
    return (
      <Fragment>
        <span className={classes.title2}>Order Summary</span>
        <OrderSummaryList />
        <span
          className={classes.title}
          style={{
            width: '100%',
            textAlign: 'center',
            borderTop: '1px solid #e5e5e5',
            paddingTop: '1em',
          }}
        >
          Enter Delivery Information
          <br />
          <br />
          {/*
          <span style={{ fontSize: '10pt' }}>(Or&nbsp;
          <NavLink to="/login" className={classes.linkToLogin}>
            Log In
          </NavLink>
          &nbsp;to auto-fill)</span>
          <br/>
          <br/>
          */}
          <span style={{ fontSize: '14px' }}>
            <strong>Need Help?</strong>{' '}
            <span style={{ color: 'blue' }}>
              <a href="/customer/help">Go here</a>
            </span>
          </span>
        </span>
        <AnonymousAddressForm
          id="anonymousForm"
          sendAnonymousOrder={this.sendAnonymousOrder}
          geocodeAddress={geocodeAddress}
          phoneChange={this.onChange}
        />
        {pendingOrder && (
        <span style={{ fontSize: '12pt', fontFamily: 'Roboto' }}>
          Thanks! Please wait for text to arrive.
        </span>
        )}
        <button type="submit" form="anonymousForm" className={classes.submit}>
          Order
        </button>
        {pendingOrder && (
          <>
            <button
              type="button"
              onClick={this.resendLink}
              className={classes.resendLink}
            >
              Resend text <br /> (Is the above phone number correct?)
            </button>
            <ResendCheckPhonePopup
              isOpen={this.state.isResetConfirmationOpen}
              value={phoneValue}
              onClick={() => {
                this.setState({ isResetConfirmationOpen: false });
              }}
            />
          </>
        )}
        <CheckPhonePopup orderId={pendingOrder} value={phoneValue} />
        <span style={{ fontSize: '9pt', fontFamily: 'Roboto' }}>
          (Must be 19 or older. Driver reserves right to refuse order.)
        </span>
        <br />
        <span style={{ fontSize: '14px', fontFamily: 'Roboto' }}>
          <strong>Need Help?</strong>{' '}
          <span style={{ color: 'blue' }}>
            <a href="/customer/help">Go here</a>
          </span>
        </span>
        <br />
        <button>
          <a
            style={{
              textDecoration: 'none',
              color: palette.yellow,
              fontWeight: 100,
              paddingBottom: '2.5rem',
            }}
            href="mailto:info@boozapp.delivery"
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            info@boozapp.delivery
          </a>
        </button>
      </Fragment>
    );
  }
}

export const AnonymousOrder = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectSheet(anonymousOrder),
)(_AnonymousOrder);
