import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { NavLink } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import injectSheet from 'react-jss';
import { thunks } from '../../../../services/thunks';
import { checkAge } from '../../../styles/General/CheckAge/checkAge';
import AgeInput from './AgeInput';

const mapDispatchToProps = {
  acceptAgePolicy: thunks.acceptAgePolicy,
  disAcceptAgePolicy: thunks.disAcceptAgePolicy,
};

const legalAge = 19;

const _CheckAge = ({ classes, acceptAgePolicy, disAcceptAgePolicy, t }) => {
  const [date, setDate] = useState('');

  const isDateInvalid = useMemo(() => Number.isNaN(new Date(date)), [date]);

  const isLegalAge = useMemo(() => {
    const dobDate = new Date(date);
    const minAllowedDate = new Date();
    minAllowedDate.setFullYear(minAllowedDate.getFullYear() - legalAge);

    return dobDate <= minAllowedDate;
  }, [date]);

  const handleEnter = () => {
    if (isLegalAge) {
      acceptAgePolicy();
      return;
    }

    disAcceptAgePolicy();
  };

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <NavLink to="/become-a-driver" className={classes.becomeDriver}>
          {t('Become a driver')}
        </NavLink>
        <h1 className={classes.boozApp}>Boo Zapp</h1>
        <hr className={classes.hr} />
        <h2 className={classes.welcome}>{t('Welcome')}</h2>
        <h2 className={classes.drinks}>{t('After Hours Drinks')}</h2>
        <h2 className={classes.hours}>
          <center>
            <strong>{t('Stay Safe! Stay Healthy!')}</strong>
          </center>
        </h2>
        <br />
        <p className={classes.question}>{t('Are you at least 19 years old?')}</p>

        <AgeInput setDate={setDate} />

        <div className={classes.buttonContainer}>
          <button
            type="button"
            disabled={isDateInvalid}
            onClick={handleEnter}
            className={classes.buttonYes}
          >
            {t('Enter')}
          </button>
        </div>

      </div>
    </div>
  );
};

export const CheckAge = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withNamespaces(),
  injectSheet(checkAge),
)(_CheckAge);
