import { push } from 'connected-react-router';

export const acceptAgePolicy = () => dispatch => {
  localStorage.setItem('isUserAdult', 'true');
  dispatch(push('/'));
};

export const disAcceptAgePolicy = () => dispatch => {
  dispatch(push('/underage'));
};
