import webBackground from '../../../images/BG_splash_screens_web.png';
import mobileBackground from '../../../images/Background_splash_screen_mobile.png';
import { palette, media } from '../../theme';

export const checkAge = {
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    background: `url(${mobileBackground}) no-repeat center `,
    backgroundSize: '100% 100%',
    '@media screen and (min-width: 500px)': {
      background: `url(${webBackground}) no-repeat center `,
      backgroundSize: '1920px 1080px',
    },
    [media.desktop]: {
      backgroundSize: '100% 100%',
    },
  },
  wrapper: {
    width: '100%',
    height: '100%',
    maxWidth: '600px',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1rem 4rem 6rem 2rem 3rem 2rem 4rem 5rem 10rem auto',
    gridTemplateAreas: `
      '.'
      'a'
      'b'
      'c'
      'd'
      'e'
      'f'
      'g'
      'i'
      'h'
    `,
    [media.desktop]: {
      maxWidth: '1000px',
      gridTemplateRows: '2% 9% 24% 6% 12% 7% 7% 5% 15% auto',
    },
  },
  becomeDriver: {
    gridArea: 'a',
    justifySelf: 'center',
    width: '65%',
    height: '2.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${palette.white}`,
    color: palette.white,
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: '500',
    textTransform: 'uppercase',
    cursor: 'pointer',
    [media.desktop]: {
      width: '14rem',
      height: '3.125rem',
      justifySelf: 'end',
    },
  },
  boozApp: {
    gridArea: 'b',
    justifySelf: 'center',
    fontFamily: 'Righteous, cursive',
    fontSize: '3rem',
    textTransform: 'uppercase',
    color: palette.yellow,
    margin: 0,
    [media.desktop]: {
      fontSize: '7.5rem',
      textTransform: 'none',
      fontWeight: '400',
    },
  },
  hr: {
    gridArea: 'c',
    justifySelf: 'center',
    width: '90%',
    border: 'none',
    height: '1px',
    backgroundColor: palette.white,
    margin: 0,
    [media.desktop]: {
      width: '70%',
    },
  },
  welcome: {
    gridArea: 'd',
    justifySelf: 'center',
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '2.25rem',
    fontWeight: '500',
    color: palette.white,
    margin: 0,
    textTransform: 'uppercase',
    [media.desktop]: {
      fontSize: '4.125rem',
    },
  },
  drinks: {
    gridArea: 'e',
    justifySelf: 'center',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '1rem',
    fontWeight: '300',
    color: palette.white,
    textTransform: 'uppercase',
    margin: 0,
    [media.desktop]: {
      fontSize: '2.25rem',
    },
  },
  hours: {
    gridArea: 'f',
    justifySelf: 'center',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '1rem',
    fontWeight: '300',
    color: palette.yellow,
    textTransform: 'uppercase',
    margin: 0,
    [media.desktop]: {
      fontSize: '2.25rem',
    },
  },
  question: {
    gridArea: 'g',
    justifySelf: 'center',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '1rem',
    fontWeight: '300',
    color: palette.white,
    textTransform: 'uppercase',
    margin: 0,
    [media.desktop]: {
      fontSize: '2.25rem',
    },
  },
  buttonNo: {
    gridArea: 'h',
    justifySelf: 'center',
    width: '65%',
    height: '3.125rem',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: '500',
    color: palette.white,
    backgroundColor: palette.yellow,
    cursor: 'pointer',
    textTransform: 'uppercase',
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [media.desktop]: {
      width: '14rem',
      justifySelf: 'end',
      marginRight: '25%',
    },
  },
  buttonContainer: {
    width: '100%',
    height: 'min-content',
  },
  buttonYes: {
    composes: '$buttonNo',
    gridArea: 'i',
    margin: '0 auto',
    [media.desktop]: {
      gridArea: 'h',
      justifySelf: 'start',
    },

    '&:disabled': {
      opacity: 0.6,
      cursor: 'not-allowed',
    }
  },
};
