import { button } from 'ui/styles/Commons/button';

export const notifacationModal = {
  backstage: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(225, 225, 225, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    width: '700px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
    backgroundColor: 'white',
    boxShadow: '0 11px 32px 2px rgba(0,0,0,0.1)',
    zIndex: 10,
    '& > h1': {
      fontFamily: 'Roboto',
      fontSize: '1.75rem',
      fontWeight: 400,
      marginTop: '3rem',
      textAlign: 'center',
      width: '80%',
      lineHeight: '2.5rem',
    },
    '& > p': {
      fontFamily: 'Roboto',
      fontSize: '1.25rem',
      fontWeight: 400,
      marginTop: '3rem',
      textAlign: 'center',
      width: '80%',
      lineHeight: '2.5rem',
    },
  },
  cancel: {
    ...button.white,
    gridArea: 'b',
    alignSelf: 'center',
    justifySelf: 'start',
  },
  ok: {
    ...button.yellow,
   margin: '2rem 0',
  },
};

